// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[2]!../../../node_modules/resolve-url-loader/index.js??ruleSet[1].rules[1].oneOf[7].use[3]!../../../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[4]!../../../node_modules/@fortawesome/fontawesome-free/css/all.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.red-heart-checkbox {
  visibility: hidden;
}
.red-heart-checkbox + label {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  font-size: 30px;
  padding-left: 15px;
  transition: all 0.2s;
}
.red-heart-checkbox:checked + label:before {
  content: "\\f004";
  color: #F00;
}
.red-heart-checkbox + label:before {
  width: 31.5px;
  height: 30px;
  content: "";
  position: absolute;
  margin-left: -30px;
  margin-top: -30px;
  font-family: FontAwesome;
  font-size: 30px;
  content: "\\f08a";
  transition: all 0.3s;
  color: #888;
}

.heart-container {
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: end;
  height: 96%;
  width: 100%;
  position: absolute;
}

.control-group {
  margin: 3px;
}

.control-group label {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/heart.scss"],"names":[],"mappings":"AA8BA;EAIE,kBAAA;AA/BF;AAiCE;EAdA,2BAAA;EACA,yBAAA;EAIA,iBAAA;EAWE,eALK;EAML,kBAAA;EACA,oBAAA;AA1BJ;AA6BE;EACE,gBAzCI;EA0CJ,WAdQ;AAbZ;AA8BE;EAnCA,aAAA;EACA,YAmBO;EAlBP,WAAA;EACA,kBAAA;EACA,kBAAA;EACA,iBAAA;EAXA,wBAAA;EACA,eAyBO;EAxBP,gBALU;EA+CR,oBAAA;EACA,WApBU;AADd;;AA6BA;EACE,aAAA;EACA,sBAAA;EACA,oBAAA;EACA,gBAAA;EACA,WAAA;EACA,WAAA;EACA,kBAAA;AA1BF;;AA6BA;EACE,WAAA;AA1BF;;AA6BA;EACE,eAAA;AA1BF","sourcesContent":["@import \"bourbon\";\n@import '~@fortawesome/fontawesome-free/css/all.css';\n\n$heart: \"\\f004\";\n$openheart: \"\\f08a\";\n\n@mixin checkmark($size) {\n  font-family: FontAwesome;\n  font-size: $size;\n  content: $openheart;\n}\n\n@mixin box-style($size) {\n  width: 1.05*$size;\n  height: $size;\n  content: \"\";\n  position: absolute;\n  margin-left: -1*$size;\n  margin-top: -1*$size;\n}\n\n@mixin no-select {\n  -webkit-touch-callout: none;\n  -webkit-user-select: none;\n  -khtml-user-select: none;\n  -moz-user-select: none;\n  -ms-user-select: none;\n  user-select: none;\n}\n  \n.red-heart-checkbox {\n  $primary: #F00;\n  $secondary: #888;\n  $size: 30px;\n  visibility: hidden;\n\n  & + label {\n    @include no-select;\n    font-size: $size;\n    padding-left: 0.5*$size;\n    transition: all 0.2s;\n  }\n\n  &:checked + label:before {\n    content: $heart;\n    color: $primary;\n  }\n\n  & + label:before {\n    @include box-style($size);\n    @include checkmark($size);\n    transition: all 0.3s;\n    color: $secondary;\n  }\n\n//   & + label:hover:before {\n//     transform: scale(1.2);\n//   }\n}\n\n.heart-container {\n  display: flex;\n  flex-direction: column;\n  justify-content: end;\n  align-items: end;\n  height: 96%;\n  width: 100%;\n  position: absolute;\n}\n\n.control-group {\n  margin: 3px;\n}\n\n.control-group label{\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
